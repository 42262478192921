import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Navbar from './NavBar';
import SummaryView from './SummaryView';
import Swal from 'sweetalert2';
import QuotationSummary from './QuotationSummary';
import AddonsSection from './addons';
import '../style/BuyPlan.css';
import pdfimage from '../images/pdf-img.jpeg';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import stamp from '../images/stamp.jpeg';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from './Modal';
const BuyPlan = () => {
  const openInNewTab = () => {
    window.open(window.location.href, '_blank');
  };
  const showAlert = (message) => {
    const alertBox = document.createElement('div');
    alertBox.className = 'alert-box';
    alertBox.textContent = message;
    document.body.appendChild(alertBox);
    setTimeout(() => {
      alertBox.remove();
    }, 2000);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sports, setSports] = useState([]);
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState('');
  const [filteredSports, setFilteredSports] = useState([]);
  const [rows, setRows] = useState([{ sport: '', date: '', startTime: '', endTime: '', no_of_arenas: 1, availableStartTimes: [], availableEndTimes: [] }]);
  const [selectedSport, setSelectedSport] = useState('');
  const [numberOfCourts, setNumberOfCourts] = useState(0);
  const [maxCourts, setMaxCourts] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [quotationData, setQuotationData] = useState({ products: [], selectedAddOns: [] });
  const [maxCourtsError, setMaxCourtsError] = useState('');
  const [availableTimings, setAvailableTimings] = useState([]);

  const centerInfo = {
    'Machaxi Badminton Centre Munnekollal': {
      helpline: '8792920136',
      location: 'https://maps.app.goo.gl/bhTeTr91aGB7kmSp8'
    },
    'Machaxi Sumukha Badminton Centre': {
      helpline: '8050354469',
      location: 'https://maps.app.goo.gl/jq3Pnqqumrm6hCCF8'
    },
    'Machaxi AJ Badminton Centre': {
      helpline: '8792920160',
      location: 'https://maps.app.goo.gl/nX81MJDMmHv7J7Fz6'
    },
    'Machaxi J Sports': {
      helpline: '7204986282',
      location: 'https://maps.app.goo.gl/ThgP9HyaFCta4SG48'
    },
    'Machaxi Ahalya Sports Centre': {
      helpline: '8792920164',
      location: 'https://maps.app.goo.gl/6QbhGfLZPGxcfcWg9'
    },
    'Machaxi Play9 Sports Centre': {
      helpline: '8050354468',
      location: 'https://maps.app.goo.gl/qzUvRkfUuD1tpddd9'
    },
    'Machaxi Cross Court Badminton Centre': {
      helpline: '8050354467',
      location: 'https://maps.app.goo.gl/bALmDqxCcrUATfgo7'
    },
    'Machaxi Maagniv Badminton Centre': {
      helpline: '7204986284',
      location: 'https://maps.app.goo.gl/2YfptN8vXLGQ8YGH7'
    },
    'Machaxi Pragathi Badminton Centre': {
      helpline: '8050354465',
      location: 'https://maps.app.goo.gl/JkToRsD4B9k12ptC6'
    },
    'Machaxi Nadando Swimming Centre': {
      helpline: '8050354471',
      location: 'https://maps.app.goo.gl/R4SuJHmvsvBowpqj6'
    },
    'Machaxi Milan Sports Centre': {
      helpline: '8050354463',
      location: 'https://maps.app.goo.gl/z3hWKJzviPYs72xJ8'
    },
    'Machaxi Sportexx Sports Centre': {
      helpline: '7204986285',
      location: 'https://maps.app.goo.gl/LayYEYMDEvdvWW288'
    },

    'Machaxi Ready to Rally Sports Centre': {
      helpline: '8792920133',
      location: 'https://maps.app.goo.gl/hb3WRaLkF1LSK5wV6'
    },
    'Machaxi - AJ Hoodi': {
      helpline: '8792920162',
      location: 'https://maps.app.goo.gl/svKvyZzF1dTiT3sh8'
    },
    'Machaxi - Lets Play': {
      helpline: '8050354470',
      location: 'https://maps.app.goo.gl/cgq17ZX21Re9AsnD6'
    },
    'Machaxi Playtm': {
      helpline: '8050354464',
      location: 'https://maps.app.goo.gl/cofwFyG4rtXNBR2TA'
    },
    'Machaxi J C Sports Centre': {
      helpline: '8050354462',
      location: 'https://maps.app.goo.gl/uRmeP7EYhwkAwSM47'
    },
    'Machaxi Sports Centre Whitefield': {
      helpline: '8050354472',
      location: 'https://maps.app.goo.gl/qzUvRkfUuD1tpddd9'
    },
    // Add more centers as needed
  };

  useEffect(() => {
    axios.get('https://admin.machaxi.com/summer-api/get-data', {
      headers: { 'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' }
    })
      .then((response) => {
        const { allPlans, allPlansTiming } = response.data;
        setSports(allPlans);
        setAvailableTimings(allPlansTiming);  // Store available timings
        setCenters([...new Set(allPlans.map(item => item.academy_name))]);
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    if (selectedCenter) {
      const filtered = sports.filter(
        sport => sport.academy_name.toLowerCase() === selectedCenter.toLowerCase()
      );
      setFilteredSports(filtered);

      filtered.forEach(sport => {
      });
    }
  }, [selectedCenter, sports]);

  useEffect(() => {
    if (selectedSport.toLowerCase() === 'badminton' || selectedSport === 'TT') {
      const selectedCenterData = filteredSports.filter(sport => sport.sport_name.toLowerCase() === selectedSport.toLowerCase());
      const max = Math.max(...selectedCenterData.map(center => center.max_avail_arenas || 0));
      setMaxCourts(max);
      setNumberOfCourts(Math.min(numberOfCourts, max));
    } else {
      setMaxCourts(0);
      setNumberOfCourts(0);
    }
  }, [selectedSport, filteredSports]);

  const [dateForms, setDateForms] = useState([
    {
      selectedDate: '',
      selectedCenter: '',
      selectionType: 'company',
      sportForms: [
        {
          selectedSport: '',
          numberOfCourts: 1,
          hours: 1,
        },
      ],
      filteredSports: [], // Add this state to each date form
      showAddAnotherSportButton: false, // Add this state to each date form
      error: '',
    },
  ]);

  const [addonFormData, setAddonFormData] = useState([]);

  const handleAddOnFormChange = (addOns) => {
    const formattedAddOns = addOns.map(addon => ({
      name: addon.name,
      gstAmount: addon.gstAmount, // Assuming these properties exist in the addon object
      gstRate: addon.gstRate,
      price: addon.price,
      totalAmount: addon.totalAmount,
    }));

    setAddonFormData(formattedAddOns);

    // Also update quotationData with selected add-ons
    setQuotationData(prevState => ({
      ...prevState,
      selectedAddOns: formattedAddOns // Use the formatted add-ons with all properties
    }));
  };

  const [showAddAnotherSport, setShowAddAnotherSport] = useState(false);

  const handleSportSelection = (sport, dateIndex, sportIndex) => {
    const updatedDateForms = [...dateForms];
    const dateForm = updatedDateForms[dateIndex];
    const sportForm = dateForm.sportForms[sportIndex];

    sportForm.selectedSport = sport;

    if (sport.toLowerCase() !== 'badminton' && sport.toLowerCase() !== 'tt') {
      sportForm.numberOfCourts = 1;
    } else {
      const selectedSportData = dateForm.filteredSports.find(
        (filteredSport) => filteredSport.sport_name.toLowerCase().trim() === sport.toLowerCase().trim()
      );
      if (selectedSportData) {
        const max = selectedSportData.max_avail_arenas || 0;
        sportForm.maxCourts = max;
        sportForm.numberOfCourts = 1;
      }
    }

    setDateForms(updatedDateForms);
    recalculateTotalPrice();
  };



  const [showAddAnotherSportButton, setShowAddAnotherSportButton] = useState(true);

  const handleCenterChange = (e) => {
    const center = e.target.value;

    setSelectedCenter(center);

    // Synchronize the selectedCenter with dateForms
    setDateForms(prevDateForms => {
      const updatedDateForms = [...prevDateForms];
      updatedDateForms[0].selectedCenter = center; // Assuming you have only one date form to handle
      return updatedDateForms;
    });

    // Reset rows when a new center is selected
    setRows([{ sport: '', date: '', startTime: '', endTime: '' }]);
  };



  const handleCourtCountChange = (increment, dateIndex, sportIndex) => {
    setDateForms(prevDateForms => {
      const updatedDateForms = [...prevDateForms];
      const dateForm = updatedDateForms[dateIndex];
      const sportForm = dateForm.sportForms[sportIndex];
      const newCount = sportForm.numberOfCourts + increment;

      // Prevent negative counts and check against max courts
      if (newCount < 0) {
        sportForm.numberOfCourts = 0; // Prevent negative counts
        setErrorMessage('Court count cannot be less than 0');
      } else if (newCount > sportForm.maxCourts) {
        // If the new count exceeds maxCourts, do not allow increment
        sportForm.numberOfCourts = sportForm.maxCourts; // Set to max if exceeded
        setMaxCourtsError(`Maximum courts available for this center is ${sportForm.maxCourts}`);
        return updatedDateForms; // Return early to prevent further updates
      } else {
        sportForm.numberOfCourts = newCount; // Set to new count
        setMaxCourtsError(''); // Clear error if within limits
      }


      return updatedDateForms;
    });

    // After updating court count, recalculate the total price
    recalculateTotalPrice();
  };


  // Log the updated quotation data before setting it to state
  const recalculateTotalPrice = () => {
    const updatedQuotationData = {};

    dateForms.forEach((dateForm, dateIndex) => {
      dateForm.sportForms.forEach((sportForm, sportIndex) => {
        if (sportForm.selectedSport) {
          // Find the selected sport data from the corresponding date form's filteredSports
          const selectedSportData = dateForm.filteredSports.find(sport =>
            sport.sport_name.toLowerCase().trim() === sportForm.selectedSport.toLowerCase().trim()
          );

          // Check if we successfully found the sport data
          if (selectedSportData) {
            const unitPrice = selectedSportData.price_per_arena || 0;
            const gst = selectedSportData.GST || 0;

            // Calculate the total price for this sport
            const gstAmount = (unitPrice * gst / 100) * sportForm.numberOfCourts * sportForm.hours;
            const totalPrice = (unitPrice * sportForm.numberOfCourts * sportForm.hours) + gstAmount;

            // Update the accumulated data
            if (updatedQuotationData[sportForm.selectedSport]) {
              // If the sport is already in the quotation, accumulate the values
              updatedQuotationData[sportForm.selectedSport].totalPrice += totalPrice;
              updatedQuotationData[sportForm.selectedSport].hours += sportForm.hours;
              updatedQuotationData[sportForm.selectedSport].instances.push({ dateIndex, sportIndex });
            } else {
              // Otherwise, add a new entry for the sport
              updatedQuotationData[sportForm.selectedSport] = {
                product: sportForm.selectedSport,
                unitPrice: unitPrice,
                gst: gst,
                gstAmount: gstAmount,
                totalPrice: totalPrice,
                hours: sportForm.hours,
                instances: [{ dateIndex, sportIndex }],
              };
            }
          } else {
          }
        }
      });
    });

    // Update the quotation data state
    setQuotationData({ products: Object.values(updatedQuotationData) });
  };


  const sportSliderRef = useRef(null);
  const handleAddAnotherSport = (dateIndex) => {
    const updatedDateForms = [...dateForms];
    const dateForm = updatedDateForms[dateIndex];
    const lastSportForm = dateForm.sportForms[dateForm.sportForms.length - 1];

    // Check if a sport has been selected in the last sport form
    if (!lastSportForm.selectedSport) {
      showAlert('Please select a sport before adding another.'); // Pop-up alert
      return; // Prevent adding another sport form
    }

    // Check if all sports have been selected
    const selectedSports = dateForm.sportForms.map(form => form.selectedSport.toLowerCase());
    const availableSports = dateForm.filteredSports.map(sport => sport.sport_name.toLowerCase());
    const unselectedSports = availableSports.filter(sport => !selectedSports.includes(sport));

    // Show alert if all sports have been selected
    if (unselectedSports.length === 0) {
      showAlert('All sports have been selected.'); // Pop-up alert
      return; // Prevent adding another sport form
    }

    // Add a new sport form only if there are unselected sports remaining
    dateForm.sportForms.push({
      selectedSport: '', // Reset sport type for new form
      numberOfCourts: 1, // Default number of courts
      hours: 1, // Default hours
    });

    // Hide the "Add Another Sport" button if all sports have been selected
    dateForm.showAddAnotherSportButton = unselectedSports.length > 0;

    // Update the state with the new sport form
    setDateForms(updatedDateForms);
  };

  const availableSportsForCenter = (center) => {
    return filteredSports.filter(sport => {
      return sport.academy_name.toLowerCase() === center.toLowerCase() && !removedSports.includes(sport.sport_name.toLowerCase());
    });
  };

  const handleDateFormChange = (dateIndex, field, value) => {
    const updatedDateForms = [...dateForms];
    updatedDateForms[dateIndex][field] = value;
    setDateForms(updatedDateForms);
  };


  const handleSubmit = async () => {
    // Validate form data before submission
    for (const dateForm of dateForms) {
      if (!dateForm.selectedDate) {
        showAlert('Please select a date.');
        return;
      }
      if (!dateForm.selectedCenter) {
        showAlert('Please select a center.');
        return;
      }
      if (dateForm.sportForms.some(sport => !sport.selectedSport || sport.numberOfCourts <= 0)) {
        showAlert('Please select a sport and specify the number of courts.');
        return;
      }
    }

    const numberToWords = (num) => {
      const units = [
        '',
        'One',
        'Two',
        'Three',
        'Four',
        'Five',
        'Six',
        'Seven',
        'Eight',
        'Nine',
        'Ten',
        'Eleven',
        'Twelve',
        'Thirteen',
        'Fourteen',
        'Fifteen',
        'Sixteen',
        'Seventeen',
        'Eighteen',
        'Nineteen',
      ];

      const tens = [
        '',
        '',
        'Twenty',
        'Thirty',
        'Forty',
        'Fifty',
        'Sixty',
        'Seventy',
        'Eighty',
        'Ninety',
      ];

      const scales = ['', 'Thousand', 'Million', 'Billion'];

      const words = (n) => {
        if (n < 20) return units[n];
        if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 > 0 ? ' ' + units[n % 10] : '');
        if (n < 1000) return units[Math.floor(n / 100)] + ' Hundred' + (n % 100 > 0 ? ' ' + words(n % 100) : '');
        for (let i = 0; i < scales.length; i++) {
          const scaleValue = Math.pow(1000, i);
          if (n < scaleValue * 1000) {
            return words(Math.floor(n / scaleValue)) + ' ' + scales[i] + (n % scaleValue > 0 ? ' ' + words(n % scaleValue) : '');
          }
        }
      };

      return words(Math.floor(num)) + ' Rupees'; // Convert only the integer part to words
    };


    // Prepare addons in the required structure
    const formattedAddons = addonFormData.map(addon => ({
      name: addon.name,
      gstAmount: addon.gstAmount, // Assuming these properties exist in the addon object
      gstRate: addon.gstRate,
      price: addon.price,
      totalAmount: addon.totalAmount,
    }));

    // Prepare bookings in the required structure
    // const formattedBookings = dateForms.flatMap(dateForm =>
    //   dateForm.sportForms.map(sportForm => {
    //     if (!sportForm.startTime || !sportForm.endTime) {
    //       throw new Error("Start time and end time are required for all bookings");
    //     }
    //     return {
    //       date_of_tournament: dateForm.selectedDate,
    //       sports: {
    //         sport: sportForm.selectedSport,
    //         no_of_arenas: sportForm.numberOfCourts,
    //       },
    //       start_time: sportForm.startTime,
    //       end_time: sportForm.endTime,
    //     };
    //   })
    // );
    const formattedBookings = rows.map(row => ({
      date_of_tournament: row.date, // Ensure this is in YYYY-MM-DD format
      sports: {
        sport: row.sport,
        no_of_arenas: row.courts || 1,  // 🔹 Ensure default value of 1
      },
      start_time: row.startTime, // Should be in HH:mm:ss
      end_time: row.endTime, // Should be in HH:mm:ss
    }));


    const generatePDF = (uniqueQuotationId, submissionData, costDetails) => {

      const doc = new jsPDF();
      const img = new Image();
      img.src = pdfimage;

      return new Promise((resolve) => {
        img.onload = function () {
          const imgX = 25;
          const imgY = 30;
          const imgWidth = 40;
          const imgHeight = 40;

          // 🏆 Title Section
          doc.setFontSize(20);
          doc.setFont('helvetica', 'bold');
          const titleText = 'Quotation';
          const titleTextWidth = doc.getTextWidth(titleText);
          const pageWidth = doc.internal.pageSize.getWidth();
          const titleXPosition = (pageWidth - titleTextWidth) / 2;
          doc.text(titleText, titleXPosition, 25);
          doc.addImage(img, 'PNG', imgX, imgY, imgWidth, imgHeight);

          // 🏆 Company Details
          doc.setFontSize(10);
          doc.setFont('helvetica', 'normal');
          const companyDetailsX = imgX + imgWidth + 30;

          doc.text('Machaxi (Septathlon Services Pvt. Ltd)', companyDetailsX, imgY + 10);
          doc.text('# 207, HAL Varthur Main Road, Marathahalli, Spice Garden,', companyDetailsX, imgY + 15);
          doc.text('Purva Riviere, Bengaluru, Karnataka - 560037', companyDetailsX, imgY + 20);
          doc.text('Bangalore Karnataka 560037', companyDetailsX, imgY + 25);
          doc.text('India', companyDetailsX, imgY + 30);
          doc.text('GSTIN 29ABCC3C569K1ZS', companyDetailsX, imgY + 35);

          // 🏆 Quotation Details
          const currentDate = new Date().toLocaleDateString();
          const personName = submissionData.person || 'N/A';
          const companyName = submissionData.isCompany ? (submissionData.organisation || 'N/A') : null;
          const tableData = [['Quotation No.', uniqueQuotationId], ['Date', currentDate], ['Name', personName]];
          if (companyName) tableData.push(['Company Name', companyName]);
          tableData.push(['Place of Supply', 'Karnataka(29)']);

          doc.autoTable({
            head: [['Details', '']],
            body: tableData,
            startY: 75,
            theme: 'plain',
            styles: { fontSize: 10, textColor: [0, 0, 0] },
            headStyles: { fillColor: [22, 160, 133], textColor: [255, 255, 255], fontSize: 12, fontStyle: 'bold' },
            columnStyles: { 0: { fontStyle: 'bold' } }
          });

          // 🏆 Booking Details with Cost Breakdown
          const bookings = costDetails.map((booking) => [
            booking.date,
            booking.sport,
            booking.start_time,
            booking.end_time,
            booking.total_cost === 0 ? '-' : booking.total_cost, // Show '-' if total cost is 0
            booking.total_cost === 0 ? '-' : `${booking.GST}%`, // Make GST 0% if total cost is 0
            booking.total_cost === 0 ? 'UNAVAILABLE' : booking.final_cost > 0 ? booking.final_cost : 'Unavailable', // Ensure final cost is 'UNAVAILABLE' if total cost is 0
          ]);
          
          
          // Calculate total final cost
          const totalFinalCost = costDetails.reduce((sum, booking) => sum + (booking.total_cost === 0 ? 0 : booking.final_cost), 0);
          
          // Add a total row if there are bookings
          if (bookings.length > 0) {
            bookings.push(['', '', '', '', '', 'Total', totalFinalCost]);
          }
          
          doc.autoTable({
            head: [['Date', 'Sport', 'Start Time', 'End Time', 'Price per Unit', 'GST %', 'Cost']],
            body: bookings.length > 0 ? bookings : [['-', '-', '-', '-', '-', '-', '-']],
            startY: doc.previousAutoTable.finalY + 10,
            theme: 'grid',
            styles: { cellPadding: 5, fontSize: 10, halign: 'center', textColor: [0, 0, 0] },
            headStyles: { fillColor: [22, 160, 133], textColor: [255, 255, 255], fontSize: 12, fontStyle: 'bold' },
            didParseCell: function (data) {
              const totalCostColumnIndex = 4; // Column index for "Total Cost"
              const finalCostColumnIndex = 6; // Column index for "Final Cost"
          
              if (data.row.index < bookings.length) {
                const totalCost = bookings[data.row.index][totalCostColumnIndex];
          
                // Make row red if total cost is 0
                if (totalCost === 0 || totalCost === '-') {
                  data.cell.styles.fillColor = [255, 102, 102]; // Red background
                  data.cell.styles.textColor = [255, 255, 255]; // White text for contrast
                }
          
                // Make "Unavailable" and "Total" text red in Final Cost column
                if (data.column.index === finalCostColumnIndex && 
                    (data.cell.text[0] === 'Unavailable' || data.cell.text[0] === 'Total')) {
                  data.cell.styles.textColor = [255, 0, 0];
                  data.cell.styles.fontStyle = 'bold';
                }
              }
            }
          });
          

          const addonsStartY = doc.previousAutoTable.finalY + 10; // Start below the last table

          // Calculate the total amount of all add-ons (without GST), rounding to two decimal places
          const totalAddonAmount = submissionData.addons.reduce((total, addon) => total + parseFloat(addon.totalAmount), 0).toFixed(2);

          // Prepare the addon details for the table
          const addonDetails = submissionData.addons.map(addon => [
            addon.name,
            `Rs ${addon.price}`,
            `Rs ${addon.totalAmount}`
          ]);

          // Add the total amount row
          addonDetails.push([
            '', // Label for the total row
            'Total', // Empty for price
            `Rs ${totalAddonAmount}` // Display the total amount
          ]);

          doc.autoTable({
            head: [['Add-on', 'Price Per Unit', 'Total Amount']],
            body: addonDetails.length > 0 ? addonDetails : [['-', '-', '-']],
            startY: addonsStartY,
            theme: 'grid',
            styles: { cellPadding: 5, fontSize: 10, halign: 'center', textColor: [0, 0, 0] },
            headStyles: { fillColor: [22, 160, 133], textColor: [255, 255, 255], fontSize: 12, fontStyle: 'bold' }
          });

          // **Terms & Conditions Page**
          doc.addPage(); // Add a new page for Terms & Conditions

          // Terms & Conditions Header
          const termsYStart = 30;
          doc.setFontSize(12);
          doc.setFont('helvetica', 'bold');
          doc.text('Terms & Conditions', 20, termsYStart);

          doc.setFontSize(10); // Set font size for body text
          doc.setFont('helvetica', 'normal');

          // Define the Terms & Conditions text
          const termsText = [
            "Thank you for considering Machaxi for hosting your corporate tournament. Please take a moment to review our booking policies to ensure a seamless experience:",
            "1. Quotation and Availability",
            "   - The provided quotation is for inquiry purposes only and does not guarantee slot or service availability. Slot confirmation will be communicated by our executives through your preferred mode of contact—via phone, WhatsApp, or email.",
            "   - Add-on services are excluded from the initial quotation and will be included in the final invoice upon confirmation from our executives and partnered vendors.",
            "2. Food Services Policy",
            "   - Outside food services are strictly prohibited. Exceptions may be made for certain Machaxi venues, but at an additional cost. It is the customer's responsibility to inform us about external food arrangements before confirming the event or making any payments. Machaxi reserves the right to deny external food services if not pre-approved, and no refunds will be issued in such cases.",
            "3. Cancellation Policy",
            "   - More than 10 days in advance: No cancellation fees apply.",
            "   - 5-10 days in advance: A 20% cancellation fee will be charged based on the total invoice amount.",
            "   - 3-5 days in advance: A 50% cancellation fee will apply.",
            "   - Less than 3 days in advance: The full invoice amount will be charged as a penalty.",
            "4. Rescheduling Policy",
            "   - Rescheduling of the event can be requested up to 7 days before the tournament date. Any rescheduling requests made after this period may not be accommodated.",
            "5. Add-on Services",
            "   - Cancellation or rescheduling of any confirmed add-on services (e.g., equipment, catering, etc.) is not permitted. The full invoice amount for these services will be charged regardless of cancellation.",
            "We appreciate your understanding and cooperation in adhering to these terms. Should you have any questions or require further clarification, please feel free to contact us."
          ];

          // Define the maximum width for the text (in mm)
          const maxWidth = 180; // Adjust according to your page width
          let currentYPosition = termsYStart + 10; // Initialize current Y position, added spacing below the title
          const pageHeight = doc.internal.pageSize.height; // Get the height of the page

          // Function to handle text rendering with proper alignment for bullet points and regular text
          const renderTextWithAlignment = (text) => {
            const splitLines = doc.splitTextToSize(text, maxWidth);
            splitLines.forEach((splitLine) => {
              // Check if the current Y position exceeds the page height
              if (currentYPosition + 10 > pageHeight) { // 10 for margin
                doc.addPage(); // Add a new page
                currentYPosition = 20; // Reset Y position for the new page
              }

              doc.setFontSize(10); // Ensure font size is set to 10 for body text
              doc.setFont('helvetica', 'normal');

              // Align bullet points starting with '-' to the left (no indentation)
              if (text.startsWith("   -")) {
                doc.text(splitLine, 20, currentYPosition); // Left align the first line of bullet points
              } else {
                doc.text(splitLine, 20, currentYPosition); // Regular text without modification
              }

              currentYPosition += 7; // Adjust the line spacing for better readability
            });
          };

          // Add each line of the Terms & Conditions
          termsText.forEach((line) => {
            renderTextWithAlignment(line);
          });
          const stampImg = new Image();
          stampImg.src = stamp;
          const stampWidth = 48;
          const stampHeight = 45;
          const stampX = doc.internal.pageSize.getWidth() - stampWidth - 20;
          const stampY = currentYPosition + 10;

          doc.addImage(stampImg, 'PNG', stampX, stampY, stampWidth, stampHeight);

          const signatureText = 'Authorized Signature';
          const signatureTextWidth = doc.getTextWidth(signatureText);
          const signatureX = stampX + (stampWidth - signatureTextWidth) / 2;
          const signatureY = stampY + stampHeight + 5;

          doc.setFontSize(12);
          doc.setFont('helvetica', 'normal');
          doc.text(signatureText, signatureX, signatureY);
          // Save PDF
          doc.save('quotation.pdf');

          const pdfBlob = doc.output('blob');
          resolve(pdfBlob);
        };

        img.onerror = function () {
          resolve(null);
        };
      });
    };
    // Prepare the submission data
    const submissionData = {
      person: dateForms[0].selectionType === 'company' ? dateForms[0].companyContactPerson : dateForms[0].individualName,
      organisation: dateForms[0].selectionType === 'company' ? dateForms[0].companyName : '',
      contact_number: dateForms[0].companyContactNumber,
      centre: selectedCenter,
      isCompany: dateForms[0].selectionType === 'company',
      addons: formattedAddons,
      bookings: rows.map(row => ({
        date_of_tournament: row.date,
        sports: { sport: row.sport, no_of_arenas: row.courts },
        start_time: row.startTime,
        end_time: row.endTime,
      })),
    };


    try {
      const quotation = await axios.post('https://admin.machaxi.com/summer-api/get-quotations', submissionData, {
        headers: { 'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' }
      });

      if (quotation.status === 200 && quotation.data.finalCostDetails) {
        const { CostDetails, TotalAmount, AddonsDetails } = quotation.data.finalCostDetails;

        const uniqueQuotationId = `QTN-${Date.now()}-${Math.floor(Math.random() * 10000)}`;


        const pdfBlob = await generatePDF(uniqueQuotationId, submissionData, CostDetails);
        if (!pdfBlob) {
          showAlert('Failed to generate PDF. Please try again.');
          return;
        }

        // Convert PDF Blob to Base64
        const pdfBase64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(pdfBlob);
          reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get base64 part
        });

        // Final submission data with PDF
        const bookingDataWithPDF = {
          ...submissionData,
          pdf: pdfBase64,
          uniquequotationId: uniqueQuotationId,
        };


        // Send Data to Backend
        const response = await axios.post('https://admin.machaxi.com/summer-api/user-post-data', bookingDataWithPDF, {
          headers: { 'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' },
        });
        if (response.status === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'Quotation Generated Successfully!',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        }
      }
    } catch (error) {
      showAlert('Error during booking: ' + (error.response?.data?.message || error.message));
    }
  };

  const [removedSports, setRemovedSports] = useState([]);

  const handleRemoveSport = (dateIndex, sportIndex) => {
    const updatedDateForms = [...dateForms];
    const removedSport = updatedDateForms[dateIndex].sportForms[sportIndex].selectedSport;
    const removedHours = updatedDateForms[dateIndex].sportForms[sportIndex].hours;
    const removedNumberOfCourts = updatedDateForms[dateIndex].sportForms[sportIndex].numberOfCourts;

    // Remove the sport from the sportForms array
    updatedDateForms[dateIndex].sportForms.splice(sportIndex, 1);
    setDateForms(updatedDateForms);

    // Remove the sport from the removedSports array to allow selecting it again
    setRemovedSports(prev => prev.filter(sport => sport !== removedSport.toLowerCase()));

    // Update the quotation data state
    setQuotationData(prev => {
      const updatedProducts = prev.products.map(product => {
        if (product.product.toLowerCase() === removedSport.toLowerCase()) {
          // Deduct the hours of the removed sport from the total hours
          const updatedHours = product.hours - removedHours;
          const updatedTotalPrice = product.totalPrice - (product.unitPrice + (product.unitPrice * (product.gst / 100))) * removedNumberOfCourts * removedHours;

          // If the updated hours are zero or less, remove the product entry from the quotationData state
          if (updatedHours <= 0) {
            return null;
          }

          return {
            ...product,
            hours: updatedHours,
            totalPrice: updatedTotalPrice,
          };
        }

        return product;
      }).filter(product => product !== null); // Filter out null products

      // If no products are left, return an empty array
      if (updatedProducts.length === 0) {
        return { products: [] };
      }

      return { products: updatedProducts };
    });

    // Check if all available sports are selected
    const selectedSports = updatedDateForms[dateIndex].sportForms.map(form => form.selectedSport.toLowerCase());
    const availableSports = filteredSports.map(sport => sport.sport_name.toLowerCase());

    // If fewer sports are selected than available, show the "Add Another Sport" button
    if (selectedSports.length < availableSports.length) {
      setShowAddAnotherSportButton(true);
    }

    // Optionally, clear any error messages or update other states if needed
    setErrorMessage(''); // Clear previous error message
  };

  const [isPickerVisible, setPickerVisible] = useState(false);

  const timeSlots = [
    '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM'
  ];
  useEffect(() => {
    if (selectedSport && selectedCenter) {
      const selectedSportData = filteredSports.find(
        sport => sport.sport_name.toLowerCase() === selectedSport.toLowerCase()
      );
      if (selectedSportData) {
        const max = selectedSportData.max_avail_arenas || 0;
        setMaxCourts(max);
        setNumberOfCourts(Math.min(numberOfCourts, max));
      } else {
        setMaxCourts(0);
        setNumberOfCourts(0);
      }
    }
  }, [selectedSport, selectedCenter, filteredSports]);

  // const calculatePrice = (row) => {
  //   if (!row.sport || !row.startTime || !row.endTime) return 0;

  //   // Find the matching plan from allPlans
  //   const selectedPlan = sports.find(
  //     plan => plan.academy_name.toLowerCase() === selectedCenter.toLowerCase() &&
  //       plan.sport_name.toLowerCase() === row.sport.toLowerCase()
  //   );

  //   if (!selectedPlan) return 0;

  //   const planId = selectedPlan.id;
  //   const hourlyPrices = availableTimings.filter(timing => timing.plan_id === planId);

  //   // Convert time format for calculations
  //   const startHour = parseInt(row.startTime.split(':')[0], 10);
  //   const endHour = parseInt(row.endTime.split(':')[0], 10);
  //   const duration = endHour - startHour;

  //   let totalPrice = 0;

  //   for (let i = 0; i < duration; i++) {
  //     const currentTime = (startHour + i).toString().padStart(2, '0') + ":00:00";

  //     // Find matching price for that hour
  //     const timing = hourlyPrices.find(timing => timing.start_time === currentTime);

  //     if (timing) {
  //       totalPrice += parseFloat(timing.price) * row.courts;
  //     }
  //   }

  //   return totalPrice;
  // };


  const getAvailableTimingsForSport = (center, sport) => {
    if (!center || !sport) return { startTimes: [], endTimes: [] };

    // Find the matching plan_id from allPlans
    const matchingPlan = sports.find(
      (plan) => plan.academy_name.toLowerCase() === center.toLowerCase() &&
        plan.sport_name.toLowerCase() === sport.toLowerCase()
    );

    if (!matchingPlan) return { startTimes: [], endTimes: [] };

    const planId = matchingPlan.id;  // Extract the plan_id

    // Filter available timings based on plan_id
    const matchingTimings = availableTimings.filter((timing) => timing.plan_id === planId);

    // Extract available start and end times
    const startTimes = [...new Set(matchingTimings.map((timing) => timing.start_time))].sort();
    const endTimes = [...new Set(matchingTimings.map((timing) => timing.end_time))].sort();

    return { startTimes, endTimes };
  };



  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];

    if (field === 'date') {
      // Handle date conversion using local timezone
      if (value) {
        const year = value.getFullYear();
        const month = String(value.getMonth() + 1).padStart(2, '0');
        const day = String(value.getDate()).padStart(2, '0');
        updatedRows[index][field] = `${year}-${month}-${day}`;
      } else {
        updatedRows[index][field] = '';
      }
    } else if (field === 'sport') {
      updatedRows[index][field] = value;

      // Fetch available timings for the selected sport at the selected center
      const timingData = getAvailableTimingsForSport(selectedCenter, value) || { startTimes: [], endTimes: [], validTimeRanges: [] };

      updatedRows[index].availableStartTimes = timingData.startTimes;
      updatedRows[index].availableEndTimes = timingData.endTimes;
      updatedRows[index].validTimeRanges = timingData.validTimeRanges;
      updatedRows[index].courts = updatedRows[index].courts || 1;

      // Reset start time, end time, and price when sport changes
      updatedRows[index].startTime = "";
      updatedRows[index].endTime = "";
      updatedRows[index].price = 0;
    } else if (field === 'startTime') {
      updatedRows[index][field] = value;

      // Ensure validTimeRanges exists
      if (Array.isArray(updatedRows[index].validTimeRanges)) {
        // Find valid end times by checking all ranges that start *after* selected startTime
        const validRanges = updatedRows[index].validTimeRanges.filter(
          range => range.start_time >= value
        );

        updatedRows[index].validEndTimes = validRanges.map(range => range.end_time);
      } else {
        updatedRows[index].validEndTimes = [];
      }

      updatedRows[index].endTime = ""; // Reset end time

      // Reset end time when start time changes
    }
    else if (field === 'endTime') {
      updatedRows[index][field] = value;
    }
    else if (field === 'courts') {
      updatedRows[index][field] = value;
    }

    // ✅ Ensure price updates only if start & end times are valid
    if (updatedRows[index].startTime && updatedRows[index].endTime) {
      updatedRows[index].price = calculatePrice(updatedRows[index]);
    }

    setRows(updatedRows);

    // Update `dateForms` state
    setDateForms(prevDateForms => {
      const updatedDateForms = [...prevDateForms];
      if (updatedDateForms[index]) {
        updatedDateForms[index].selectedDate = updatedRows[index].date || updatedDateForms[index].selectedDate;
        updatedDateForms[index].sportForms = updatedRows.map(row => ({
          selectedSport: row.sport || '',
          numberOfCourts: row.courts || 1,
          startTime: row.startTime || '',
          endTime: row.endTime || '',
        }));
      }
      return updatedDateForms;
    });
    // Update `quotationData` for live updates
    setQuotationData({
      ...quotationData,
      products: updatedRows.map(row => ({
        product: row.sport || '',
        unitPrice: row.price || 0,
        totalPrice: row.price || 0,
        hours: row.startTime && row.endTime
          ? (parseInt(row.endTime.split(':')[0]) - parseInt(row.startTime.split(':')[0]))
          : 0
      }))
    });
  };


  // 🏆 Price Calculation Function
  const calculatePrice = (row) => {
    if (!row.sport || !row.startTime || !row.endTime) return 0;

    // Find the matching plan from allPlans
    const selectedPlan = sports.find(
      plan => plan.academy_name.toLowerCase() === selectedCenter.toLowerCase() &&
        plan.sport_name.toLowerCase() === row.sport.toLowerCase()
    );

    if (!selectedPlan) return 0;

    const planId = selectedPlan.id;
    const hourlyPrices = availableTimings.filter(timing => timing.plan_id === planId);

    // Convert time format for calculations
    const startHour = parseInt(row.startTime.split(':')[0], 10);
    const endHour = parseInt(row.endTime.split(':')[0], 10);
    const duration = endHour - startHour;

    let totalPrice = 0;

    for (let i = 0; i < duration; i++) {
      const currentTime = (startHour + i).toString().padStart(2, '0') + ":00:00";

      // Find matching price for that hour
      const timing = hourlyPrices.find(timing => timing.start_time === currentTime);

      if (timing) {
        totalPrice += parseFloat(timing.price) * row.courts;
      }
    }

    return totalPrice;
  };

  filteredSports.forEach(sport => {
  });
  const handleAddRow = () => {
    setRows([...rows, { sport: '', date: '', startTime: '', endTime: '' }]);
  };

  // Remove a row from the table (except the first row)
  const handleRemoveRow = (index) => {
    if (index === 0) return; // Prevent removing the first row
    const updatedRows = rows.filter((_, i) => i !== index); // Remove the row at the specified index
    setRows(updatedRows);
  };
  const getEndTimeOptions = (startTime) => {
    if (!startTime) return timeSlots; // Return all options if no startTime
    const startIndex = timeSlots.indexOf(startTime);
    return timeSlots.slice(startIndex + 1); // Return only valid times after startTime
  };
  const convertTo12HourFormat = (time24) => {
    if (!time24) return '';
    const [hours, minutes] = time24.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes} ${period}`;
  };
  const [summaryData, setSummaryData] = useState(null);
  const [showSummary, setShowSummary] = useState(false);

  const handleViewSummary = async () => {
    setIsModalOpen(true);
    const submissionData = {
      person: dateForms[0].selectionType === 'company' ? dateForms[0].companyContactPerson : dateForms[0].individualName,
      organisation: dateForms[0].selectionType === 'company' ? dateForms[0].companyName : '',
      contact_number: dateForms[0].companyContactNumber,
      centre: selectedCenter,
      isCompany: dateForms[0].selectionType === 'company',
      addons: addonFormData.map(addon => ({ name: addon.name })),
      bookings: rows.map(row => ({
        date_of_tournament: row.date,
        sports: {
          sport: row.sport,
          no_of_arenas: row.courts,
        },
        start_time: row.startTime,
        end_time: row.endTime,
      })),
    };

    try {
      const response = await axios.post('https://admin.machaxi.com/summer-api/get-quotations', submissionData, {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg',
        },
      });

      if (response.status === 200 && response.data.finalCostDetails) {
        setSummaryData(response.data);
        setShowSummary(true);
      } else {
        alert('Failed to fetch summary data. Please try again.');
      }
    } catch (error) {
      alert('Error fetching summary: ' + (error.response?.data?.message || error.message));
    }
  };
  const selectedAddOns = quotationData.selectedAddOns;
  return (
    <div className="buyplan-container">
      <Navbar />
      <div className="navbar-spacing"></div>
      <div className="buyplan-left">
        <h2 className="heading">⁠Get Quotation for Hosting Tournament at Machaxi</h2>

        {dateForms.map((dateForm, dateIndex) => (
          <div key={dateIndex} className="buyplan-form">
            {dateIndex === 0 && (
              <div className="selection-type">
                <label className="radio-label">
                  <input
                    type="radio"
                    value="company"
                    checked={dateForm.selectionType === 'company'}
                    onChange={(e) => handleDateFormChange(dateIndex, 'selectionType', e.target.value)}
                  />
                  Company
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    value="individual"
                    checked={dateForm.selectionType === 'individual'}
                    onChange={(e) => handleDateFormChange(dateIndex, 'selectionType', e.target.value)}
                  />
                  Individual
                </label>
              </div>
            )}

            {/* Conditional Inputs for Company */}
            {dateForm.selectionType === 'company' && dateIndex === 0 && (
              <div className="company-inputs">
                <label className="label">Company Name:</label>
                <input
                  type="text"
                  value={dateForm.companyName}
                  onChange={(e) => handleDateFormChange(dateIndex, 'companyName', e.target.value)}
                  className="input-text"
                />

                <label className="label">Contact Person:</label>
                <input
                  type="text"
                  value={dateForm.companyContactPerson}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                      handleDateFormChange(dateIndex, 'companyContactPerson', inputValue);
                    }
                  }}
                  className="input-text"
                />

                <label className="label">Contact Number:</label>
                <input
                  type="text"
                  value={dateForm.companyContactNumber}
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    if (value.length <= 10) {
                      handleDateFormChange(dateIndex, 'companyContactNumber', value);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  className="input-text"
                />
              </div>
            )}

            {/* Conditional Inputs for Individual */}
            {dateForm.selectionType === 'individual' && dateIndex === 0 && (
              <div className="individual-inputs">
                <label className="label">Name:</label>
                <input
                  type="text"
                  value={dateForm.individualName}
                  onChange={(e) => handleDateFormChange(dateIndex, 'individualName', e.target.value)}
                  className="input-text"
                />

                <label className="label">Contact Number:</label>
                <input
                  type="text"
                  value={dateForm.companyContactNumber}
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    if (value.length <= 10) {
                      handleDateFormChange(dateIndex, 'companyContactNumber', value);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  className="input-text"
                />
              </div>
            )}

            <div className="multiple-event-message">
              You can not select multiple centers, please refer to “Generate Another Quotation” at the bottom of the page.
            </div>

            <div className="center-selection">
              <label className="label">Select Center:</label>
              <select value={selectedCenter} onChange={handleCenterChange} className="center-dropdown">
                <option value="">-- Select --</option>
                {centers.map((center, index) => (
                  <option key={index} value={center}>{center}</option>
                ))}
              </select>
            </div>

            {selectedCenter && (
              <div>
                <div className="sport-time-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Sport</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Arena</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => {
                        // Find the selected sport's data from filteredSports
                        const selectedSportData = filteredSports.find(
                          sport => sport.sport_name.toLowerCase() === row.sport.toLowerCase()
                        );

                        // Get max_avail_arenas for the selected sport
                        const maxArenas = selectedSportData ? selectedSportData.max_avail_arenas : 0;

                        return (
                          <tr key={index} className="table-row">
                            <td className="table-cell">
                              <select
                                value={row.sport}
                                onChange={(e) => handleRowChange(index, 'sport', e.target.value)}
                                className="sport-dropdown"
                              >
                                <option value="">-- Select --</option>
                                {filteredSports.map((sport, i) => (
                                  <option key={i} value={sport.sport_name}>{sport.sport_name}</option>
                                ))}
                              </select>
                            </td>
                            <td className="table-cell">
                              <DatePicker
                                selected={row.date ? new Date(row.date) : null}
                                onChange={(date) => handleRowChange(index, 'date', date)}
                                dateFormat="dd/MM/yyyy"
                                className="custom-date-picker"
                                minDate={new Date()}
                                placeholderText="Select Date"
                                dropdownMode="select"
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            </td>
                            <td className="table-cell">
                              <select
                                value={row.startTime}
                                onChange={(e) => handleRowChange(index, 'startTime', e.target.value)}
                                className="time-dropdown"
                              >
                                <option value="">-- Select --</option>
                                {row.availableStartTimes?.map((time, i) => (
                                  <option key={i} value={time}>{convertTo12HourFormat(time)}</option>
                                ))}
                              </select>
                            </td>
                            <td className="table-cell">
                              <select
                                value={row.endTime}
                                onChange={(e) => handleRowChange(index, 'endTime', e.target.value)}
                                className="time-dropdown"
                                disabled={!row.startTime}
                              >
                                <option value="">-- Select --</option>
                                {row.availableEndTimes?.map((time, i) => {
                                  // ✅ Allow only those times greater than selected startTime
                                  const isRestricted = row.startTime && time <= row.startTime;
                                  return (
                                    <option
                                      key={i}
                                      value={time}
                                      disabled={isRestricted}
                                      className={isRestricted ? "blurred-option" : ""}
                                    >
                                      {convertTo12HourFormat(time)}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>






                            <td className="table-cell">
                              {row.sport && maxArenas > 0 ? (
                                <select
                                  value={row.courts}
                                  onChange={(e) => handleRowChange(index, 'courts', parseInt(e.target.value))}
                                  className="court-dropdown"
                                >
                                  {Array.from({ length: maxArenas }, (_, i) => (
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                  ))}
                                </select>
                              ) : (
                                <span>1</span>
                              )}
                            </td>
                            <td className="table-cell">
                              {index !== 0 && (
                                <button onClick={() => handleRemoveRow(index)} className="remove-row-button">
                                  Remove
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <button onClick={handleAddRow} className="add-row-button">
                  + Add Row
                </button>
              </div>
            )}
          </div>
        ))}

        <AddonsSection onAddOnFormChange={handleAddOnFormChange} />
        {dateForms[0]?.selectedCenter && (
  <div className="dynamic-info">
    <h3>Contact Information:</h3>
    <p>Helpline: {centerInfo[dateForms[0].selectedCenter]?.helpline || 'N/A'}</p>

    {/* Link to Google Map */}
    <p>
      <a 
        href={centerInfo[dateForms[0].selectedCenter]?.location} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        View Location on Google Maps
      </a>
    </p>
  </div>
)}

      </div>
      <div className="quotation-summary">
        <QuotationSummary
          products={quotationData.products}
          selectedAddOns={quotationData.selectedAddOns}
          handleSubmit={handleSubmit}
          handleViewSummary={handleViewSummary}
          summaryData={summaryData} // Pass summaryData here
        />
        <div className='another-quotation-container'>
          <button className="submit-button" onClick={openInNewTab}>
            Generate Another Quotation
          </button>
        </div>
      </div>
    </div>
  );

};

export default BuyPlan;