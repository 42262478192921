import React, { useState } from 'react';
import '../style/QuotationSummary.css'; // CSS for Quotation Summary styles
import Modal from './Modal'; // Importing the Modal component
import Term from './Terms'; // Importing the Term component

const QuotationSummary = ({ products, selectedAddOns = [], handleSubmit, handleViewSummary }) => {

  const [isChecked, setIsChecked] = useState(false); // State to track the checkbox
  const [isModalOpen, setIsModalOpen] = useState(false);

 
  // Map the selectedAddOns array to extract relevant details
  const addons = selectedAddOns.map(addOn => ({
    name: addOn?.name || "No name available",
    description: addOn?.description || "No description available",
    price: addOn?.price || "0.00",
    gstRate: addOn?.gstRate ?? 0, // Handle undefined GST rate
    gstAmount: addOn?.gstAmount || "0.00",
    totalAmount: addOn?.totalAmount || "0.00",
  }));


  // Group products by name and sum the hours and total price correctly
  const groupedProducts = products.reduce((acc, product) => {
    const existingProduct = acc.find(p => p.product === product.product);

    if (existingProduct) {
      // Update existing product's hours and recalculate the total price
      existingProduct.hours += Number(product.hours) || 0;
      existingProduct.totalPrice += Math.round(Number(product.totalPrice) || 0); // Round the total price
    } else {
      // Push new product into the accumulator
      acc.push({
        product: product.product,
        unitPrice: Math.round(product.unitPrice), // Round unit price
        gst: Math.round(product.gst), // Round GST percentage
        totalPrice: Math.round(Number(product.totalPrice) || 0), // Round the total price
        hours: Number(product.hours) || 0
        
      });
    }
    
    return acc;
  }, []);

  // Calculate the total price of all products
  const totalPrice = groupedProducts.reduce((total, product) => total + (product.totalPrice || 0), 0);

  // Calculate the total price of all add-ons
  const totalAddOnPrice = addons.reduce((total, addOn) => total + (Number(addOn.totalAmount) || 0), 0);

  // Function to handle modal closing and updating the isChecked state
  const handleModalClose = (agreed) => {
    setIsModalOpen(false);
    if (agreed) {
      setIsChecked(true); // Set isChecked to true if user agrees to the terms
    } else {
      setIsChecked(false); // Optionally set it back to false if user doesn't agree
    }
  };

  return (
    <div className="summary-container">
      <article className="summary-article">
        <h2 className="summary-title">Quotation Summary</h2>
        
        <div className="summary-table-container">
        <h3>Selected Sports</h3>
          <div className="scrollable-table">
            <table className="summary-table">
              
              <thead>
                <tr>
                  <th>Services</th>
                  <th>Price per Unit</th>
                </tr>
              </thead>
              <tbody>
                {groupedProducts.length > 0 ? (
                  groupedProducts.map((product, index) => (
                    <tr key={index}>
                      <td>{product.product}</td>
                      <td>₹{product.unitPrice}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">Please select a sport to view the quotation summary.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {totalPrice > 0 && (
          <div className="total-addons-price">
            <strong>Total Amount of Sports: </strong> ₹{Math.round(totalPrice)}
          </div>
        )}
        {/* Add-ons summary */}
        <h3>Selected Add-ons</h3>
        <div className="summary-table-container">
          <div className="scrollable-table">
            <table className="summary-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>GST Rate</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {addons.length > 0 ? (
                  addons.map((addOn, index) => (
                    <tr key={index}>
                      <td>{addOn.name}</td>
                      <td>₹{addOn.price}</td>
                      <td>{addOn.gstRate}%</td>
                      <td>₹{addOn.totalAmount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No add-ons selected.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Total Add-on price */}
        {totalAddOnPrice > 0 && (
          <div className="total-addons-price">
            <strong>Total Amount of Add-ons: </strong> ₹{Math.round(totalAddOnPrice)}
          </div>
        )}

        {/* Terms and Conditions checkbox */}
        <div className="terms-conditions">
          <input 
            type="checkbox" 
            id="terms" 
            checked={isChecked} 
            onChange={(e) => setIsChecked(e.target.checked)} // Allow checkbox to toggle
          /> I agree to the 
          <label 
            htmlFor="terms" 
            onClick={(e) => {
              e.preventDefault(); // Prevent default checkbox toggle
              setIsModalOpen(true); // Open the modal when the label is clicked
            }}
            style={{ cursor: 'pointer', color: 'blue' }}
          > Terms and Conditions
          </label>
        </div>

        {/* Generate Quotation button */}
        <div className='submit-button-container'>
          <button 
            className={`submit-button ${!isChecked ? 'disabled' : ''}`} 
            onClick={handleSubmit} 
            disabled={!isChecked} // Disable the button if not checked
          >
            Generate Quotation
          </button>
        </div>

        {/* Modal for Terms and Conditions */}
        <Modal isOpen={isModalOpen} onClose={() => handleModalClose(false)}>
          <Term onAgree={() => handleModalClose(true)} /> {/* Pass function to set agreement state */}
        </Modal>
      </article>
    </div>
  );
};

export default QuotationSummary;